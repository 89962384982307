import * as React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Footer from '../../components/footer';

// markup
const IndexPage = () => {
  return (
    <Layout navtype="light" title="Mayak">
      <StaticImage
        placeholder="blurred"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../../images/mayak/head.jpg"
        alt="Mayak"
        className="hero-image"
      />
      <Wrapper>
        <svg width="128" height="128" className="project-logo" alt="Farol" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M63.9999 116.6C93.1606 116.6 116.8 92.9606 116.8 63.8C116.8 34.6394 93.1606 11 63.9999 11C34.8393 11 11.2 34.6394 11.2 63.8C11.2 92.9606 34.8393 116.6 63.9999 116.6Z" fill="#0C50E9"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M56.9288 116.135C49.897 115.204 43.2977 112.902 37.4077 109.508L54.3676 53.746H53.0726C52.4835 53.746 52.006 53.2684 52.006 52.6793V40.4724C52.006 39.8833 52.4835 39.4057 53.0726 39.4057H56.3567C57.6859 35.7179 60.7404 33.2667 64.2327 33.2667C67.7261 33.2667 70.7817 35.7179 72.1099 39.4057H74.9081C75.4972 39.4057 75.9748 39.8833 75.9748 40.4724V52.6793C75.9748 53.2684 75.4972 53.746 74.9081 53.746H73.0686L90.5155 109.552C84.645 112.922 78.0725 115.208 71.0712 116.135L71.0701 109.644C71.0701 106.557 68.4663 102.611 64 102.611C59.5337 102.611 56.9299 106.557 56.9299 109.644L56.9288 116.135ZM81.2788 94.7879L77.0311 81.4001H50.9266L46.7552 94.7879H81.2788ZM74.2804 72.6343L71.3056 62.5889H56.8264L53.7008 72.6343H74.2804ZM72.5333 49.9334V43.5334C72.5333 42.9443 72.0558 42.4667 71.4667 42.4667H56.5333C55.9442 42.4667 55.4667 42.9443 55.4667 43.5334V49.9334C55.4667 50.5225 55.9442 51.0001 56.5333 51.0001H71.4667C72.0558 51.0001 72.5333 50.5225 72.5333 49.9334Z" fill="white"/>
          <path d="M0 35.5333L43.9813 42.216L44 49.6251L0.0194851 56.2437L0 35.5333ZM128 35.5333V56.2437L84 49.576V42.1659L128 35.5333Z" fill="#F8E71C"/>
        </svg>

        <h1>Farol</h1>
        <p>
          Farol is the one of the most popular analytics tools for sellers on Wildberries and Ozon marketplaces.
        </p>
        <ul>
          <strong>What has been done</strong>
          <li>Telegram Bot</li>
          <li>Chrome Extension</li>
          <li>Main application</li>
          <li>Cross-platform mobile app</li>
          <li>Landing pages</li>
        </ul>
        <ul>
          <strong>Responsibilities</strong>
          <li>Create prototypes and wireframes</li>
          <li>Developing personas and usage scenarios</li>
          <li>Communicate design solutions to engineers</li>
          <li>Translate business and user needs and technology constraints into solution concepts that are meaningful, easy to use, and engaging</li>
          <li>Generate meaningful insights that will drive hypothesis</li>
        </ul>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

const Wrapper = styled.div`
  max-width: var(--maxWidth-4xl);
  margin: auto;
  padding: 0 20px;
`


export default IndexPage
